import React from "react"
import styled from "@emotion/styled"

const StyledButton = styled("button")({
  "&:hover": {
    cursor: "pointer",
  },
  color: "#FAFAFA",
  margin: "0",
  width: "auto",
})

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)

export default Button
