import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Block from "../components/block"
import Button from "../components/button"
import Image from "../components/image"
import gcmobile from "../images/gcmobile.png"
import chicagoFreeMo from "../images/chicago-free-mo.png"
import RidgeWayneLight from "../images/ridge-wayne-light.png"
import GCMobile from "../images/GCMobile.pdf"
import Roundhouse from "../images/roundhouse.png"
import { breakPoints } from "../styling"

const StyledP = styled("div")({
  "& h1": {
    color: "#DF6767",
    fontFamily: "Lobster",
    margin: "10px 0 15px 0",
  },
  color: "#5A5A5A",
  fontSize: "30px",
  margin: "140px 0",
  textAlign: "left",
})

const StyledAboutImgWrapper = styled("div")({
  "& .gatsby-image-wrapper": {
    border: "15px solid #F4F4F4",
    [breakPoints.phone]: {
      margin: "0",
    },
    margin: "40px 0 0 40px",
  },
  width: "500px",
  [breakPoints.phone]: {
    width: "100%",
  },
})

const StyledAboutBodyP = styled("div")({
  "& .body": {
    width: "70%",
    [breakPoints.phone]: {
      width: "100%",
    },
  },
  display: "flex",
  justifyContent: "space-between",
  [breakPoints.phone]: {
    display: "block",
  },
})

const StyledWorkWrapper = styled("div")({
  "& p": {
    width: "60%",
    [breakPoints.phone]: {
      width: "100%",
    },
  },
  "& img": {
    border: "15px solid #F4F4F4",
    maxWidth: "88%",
  },
})

const ContactWrapper = styled("section")({
  "& form": {
    margin: "40px 0",
  },
  "& label": {
    color: "#646464",
    display: "block",
    margin: "0 0 20px 0",
  },
  "& input, & textarea": {
    border: "1px solid #DEDEDE",
    color: "#5A5A5A",
    display: "block",
    marginTop: "5px",
    padding: "10px",
    width: "400px",
    [breakPoints.phone]: {
      width: "100%",
    },
  },
})

const IndexPage = () => (
  <Layout>
    <SEO title="Ryan Balla - Chicago Web Developer" />
    <StyledP>
      <span>Hi, I’m</span>
      <h1>Ryan Balla</h1>
      <span>A developer, team builder, and UX designer.</span>
    </StyledP>
    <Block id="about" title="About Me">
      <StyledAboutBodyP>
        <div className="body">
          <p>
            Creating great experiences is what I’m passionate about. Streamlined
            and elegant interfaces make interacting with a Web app pleasing.
            Effective and happy development teams ensure the possibility of
            crafting these experiences. Solid development practices ensure code
            quality and app stability.
          </p>
          <p>
            I’m most engaged when having a role in the entire lifecycle of an
            app. Design is important and taking these principles to development
            and leadership make for truly effective teams. Being in a career
            that is multifaceted has been one of the best things about this
            industry and keeps me excited to work on new projects.
          </p>
        </div>
        <StyledAboutImgWrapper className="aboutMeWrapper">
          <Image />
        </StyledAboutImgWrapper>
      </StyledAboutBodyP>
    </Block>
    <Block id="work" title="Professional Work">
      <hgroup>
        <h2>Roundhouse</h2>
        <h3>Technologies:</h3>
        <ul className="listItems">
          <li>Figma</li>
          <li>InVision</li>
          <li>React.js</li>
          <li>Node.js</li>
          <li>PostgreSQL</li>
          <li>Cypress</li>
        </ul>
      </hgroup>
      <StyledWorkWrapper>
        <p>
          Roundhouse is a model railroad inventory and operations tool. It's
          designed to provide an intuitive and fun experience to what is
          normally tedious.
        </p>
        <p>
          <Button
            onClick={() => {
              window.location = "https://invis.io/REU1XKVMN7Z"
            }}
          >
            View Functional Prototype
          </Button>
        </p>
        <p>
          <img src={Roundhouse} alt="Screenshot of Roundhouse" />
        </p>
      </StyledWorkWrapper>
      <hgroup>
        <h2>Garfield-Clarendon Mobile App</h2>
        <h3>Technologies:</h3>
        <ul className="listItems">
          <li>Figma</li>
          <li>Balsamiq</li>
          <li>InVision</li>
        </ul>
      </hgroup>
      <StyledWorkWrapper>
        <p>
          The Garfield-Clarendon Model Railroad Club Mobile App is designed to
          provide an enhanced experience for our guests and club members. A
          robust admin panel allows for fine-grained management of the layout.
          Fun and educational aspects are a delight for visitors.
        </p>
        <p>
          <Button
            onClick={() => {
              window.location = GCMobile
            }}
          >
            View Wireframes
          </Button>
        </p>
        <p>
          <img
            src={gcmobile}
            alt="Screenshot of Garfield-Clarendon Mobile App"
          />
        </p>
      </StyledWorkWrapper>
      <hgroup>
        <h2>Chicago Free-Mo</h2>
        <h3>Technologies:</h3>
        <ul className="listItems">
          <li>Figma</li>
          <li>React.js</li>
          <li>Gatsby.js</li>
          <li>Netlify</li>
          <li>Contentful</li>
        </ul>
      </hgroup>
      <StyledWorkWrapper>
        <p>
          Blog and website for Chicago Free-Mo, an HO scale model railroad
          group. Built on Gatsby.js, this serverless site is deployed to Netlify
          and used Contentful for blog posts. React is used for components and
          interactive features.
        </p>
        <p>
          <Button
            onClick={() => {
              window.location = "https://chicagofreemo.org"
            }}
          >
            Visit Site
          </Button>
        </p>
        <p>
          <img src={chicagoFreeMo} alt="Screenshot of chicagofreemo.org" />
        </p>
      </StyledWorkWrapper>
      <hgroup>
        <h2>Ridge Wayne Light</h2>
        <h3>Technologies:</h3>
        <ul className="listItems">
          <li>Figma</li>
        </ul>
      </hgroup>
      <StyledWorkWrapper>
        <p>
          Designs for a proposal to add a new traffic light. Site to build
          support and show evidence of need.
        </p>
        <p>
          <img
            src={RidgeWayneLight}
            alt="Screenshot of a proposed website to build a traffic light at Ridge / Wayne"
          />
        </p>
      </StyledWorkWrapper>
    </Block>
    <Block id="contact" title="Contact">
      <ContactWrapper>
        <form
          action="contact-success"
          name="contact"
          method="post"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input type="hidden" name="bot-field" />
          <label htmlFor="fullName">
            Full Name
            <input id="fullName" name="fullName" type="text" />
          </label>
          <label htmlFor="email">
            Email
            <input id="email" name="email" type="text" />
          </label>
          <label htmlFor="message">
            Message
            <textarea name="message" id="message"></textarea>
          </label>
          <div data-netlify-recaptcha="true"></div>
          <button type="submit">Send</button>
        </form>
      </ContactWrapper>
    </Block>
  </Layout>
)

export default IndexPage
